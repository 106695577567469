import React from 'react';
import { useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import FormSelect from 'APP/components/form/FormSelect';
import FormInput from 'APP/components/form/FormInput';
import editConfig from 'APP/configs/editConfig';

const columnWith = 1.8;

const toOptions = (data) => data.map((item) => ({ name: item.name, id: item.code }));

function DeclarationInfo ({
    isEdit, packageSizes, originCountry, exportTypeData
}) {

    const { record } = useEditContext();
    const formik = useFormikContext();

    return (
        <div className="revertShipping block">
            <div className="title">報關資訊</div>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={2} md={2} className="tableItem tableTitle">商品產地</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">報關品名</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">尺寸</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">重量</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">報關方式</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">正式報關單號</Grid>
            </Grid>
            <hr />
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={2} md={2} className="tableItem tableItemBody">
                    {
                        record.status >= 30
                            ? originCountry.find((item) => item.code === record?.customsInfo?.originCountry)?.name || editConfig.common.emptyString
                            : (
                                <FormSelect
                                    options={toOptions(originCountry) || []}
                                    value={formik.values.customsInfo.originCountry}
                                    handleChange={formik.handleChange('customsInfo.originCountry')}
                                    error={formik.errors.customsInfo?.originCountry}
                                    disabled={!isEdit || record?.status >= 30}
                                    isEdit={isEdit}
                                    formControlVariant="standard"
                                />
                            )
                    }
                </Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">{record?.customsInfo?.productionType || editConfig.common.emptyString}</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">
                    {
                        (record?.customsInfo?.packageSize === 'other')
                            ? `${record?.customsInfo?.length || editConfig.common.emptyString} x ${record?.customsInfo?.width || editConfig.common.emptyString} x ${record?.customsInfo?.hight || editConfig.common.emptyString} cm`
                            : packageSizes.find((item) => item.code === record?.customsInfo?.packageSize)?.name || editConfig.common.emptyString
                    }
                </Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">{`${record?.customsInfo?.weight || editConfig.common.emptyString} kg`}</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">
                    {record?.customsInfo?.exportCustomsType
                        ? exportTypeData.find((item) => item.code === record?.customsInfo?.exportCustomsType)?.name
                        : editConfig.common.emptyString}
                </Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">
                    {
                        (isEdit) ? (
                            <FormInput
                                value={formik.values.customsInfo.productionCustomsCode}
                                handleChange={formik.handleChange('customsInfo.productionCustomsCode')}
                                error={formik.errors.customsInfo?.productionCustomsCode}
                                disabled={!isEdit}
                                variant="standard"
                            />
                        ) : (formik.values.customsInfo.productionCustomsCode || editConfig.common.emptyString)
                    }
                </Grid>
            </Grid>
        </div>
    );

}

DeclarationInfo.propTypes = {
    isEdit: PropTypes.bool,
    packageSizes: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    originCountry: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    exportTypeData: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string
    })).isRequired
};

DeclarationInfo.defaultProps = {
    isEdit: false
};

export default DeclarationInfo;
