import React from 'react';
import { EditButton, ShowButton } from 'react-admin';
import { Box } from '@mui/material';

function ActionButtons () {

    return (
        <Box
            sx={{
                alignItems: 'center', display: 'flex', justifyContent: 'space-around', marginBottom: '-5px'
            }}
        >
            {/* 編輯 */}
            <EditButton label="" sx={{ minWidth: 'initial' }} />
            {/* 檢視 */}
            <ShowButton label="" sx={{ minWidth: 'initial' }} />
        </Box>
    );

}

export default ActionButtons;
