import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import utility from 'APP/utility';
import editConfig from 'APP/configs/editConfig';

const manageStorehouse = (data = []) => {

    const options = [];
    const mapping = {};

    data.forEach((item) => {

        options.push({
            id: item.id,
            name: item.name
        });
        mapping[item.id] = item.name;
    
    });

    return { options, mapping };

};

const manageShelves = (data = []) => {

    const options = [];
    const mapping = {};

    data.forEach((item) => {

        options.push({
            id: item.id,
            name: item.name
        });
        mapping[item.id] = item.name;
    
    });

    return { options, mapping };

};

const useStorehouse = (selectedStorehouseId) => {

    const dataProvider = useDataProvider();

    const [storehouseData, setStorehouseData] = useState([]);
    const [storehouseMapping, setStorehouseMapping] = useState({});

    const [shelvesData, setShelvesData] = useState([]);
    const [shelvesMapping, setShelvesMapping] = useState({});

    //  取得倉庫列表
    useEffect(() => {

        const fetchFn = () => (dataProvider.getList(
            'storehouse',
            {
                pagination: { page: 1, perPage: 50 },
                sort: {},
                filter: { status: 1 }
            },
            { ...editConfig.fetchListQueryOptions }
        ));

        (async () => {

            const { data: storehousesRes } = await utility.fetchWithCache(fetchFn, 'storehouses');
            const { options: storehousesOptions, mapping: shMapping } = manageStorehouse(storehousesRes);
            setStorehouseData(storehousesOptions);
            setStorehouseMapping(shMapping);

        })();

    }, [dataProvider]);

    //  取得貨架列表
    useEffect(() => {

        if (!selectedStorehouseId) return;

        const fetchFn = () => (dataProvider.getList(
            'shelves',
            {
                pagination: { page: 1, perPage: 50 },
                sort: {},
                filter: { storehouseId: selectedStorehouseId }
            },
            { ...editConfig.fetchListQueryOptions }
        ));

        (async () => {

            const { data: shelvesRes } = await utility.fetchWithCache(fetchFn, 'shelves');
            const { options: shelvesOptions, mapping: sMapping } = manageShelves(shelvesRes);
            setShelvesData(shelvesOptions);
            setShelvesMapping(sMapping);

        })();

    }, [dataProvider, selectedStorehouseId]);

    return {
        storehouses: storehouseData,
        storehouseMapping,
        shelves: shelvesData,
        shelvesMapping
    };

};

export default useStorehouse;
