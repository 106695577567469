import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

const useCustomList = (resource, filterObj) => {

    const [data, setData] = useState([]);

    const dataProvider = useDataProvider();
    
    useEffect(() => {
        
        if (!resource) return;

        (async () => {

            const { data: resData } = await dataProvider.getCustomList(resource, !!filterObj ? { filter: filterObj } : {});

            setData(resData);

        })();

    }, [JSON.stringify(filterObj)]);

    return {
        data
    };

};

export default useCustomList;
