import React, {
    useCallback, useState
} from 'react';
import PropTypes from 'prop-types';
import {
    Confirm, useUpdate, useEditContext
} from 'react-admin';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';

import editConfig from 'APP/configs/editConfig';
import utility from 'APP/utility';

function RefetchShippingNumberBtn ({
    handleOpenSuccessConfirmForShipping, handleFailedMessage, shippingTypes
}) {

    const { record } = useEditContext();
    const formik = useFormikContext();
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [update] = useUpdate();

    const handleOpenConfirm = useCallback(() => {

        setIsOpenConfirm(true);

    }, []);

    const handleRefetch = useCallback(() => {

        // 重新取號
        const newData = {
            status: formik.values.status,
            action: editConfig.actionType.refetchShippingNumber,
            shippingCode: record?.shippingInfo?.shippingCode,
        };
        const preData = {
            status: record?.status,
            shippingCode: record?.shippingInfo?.shippingCode,
            shippingNumber: record?.shippingInfo?.shippingNumber
        };

        const shippingName = shippingTypes.find((item) => item.id === record?.shippingInfo?.shippingCode)?.name;

        const { newParams, preParams } = utility.dealWithUpdateCommonData(formik.values, record);

        update(
            editConfig.resource,
            {
                id: record?.id,
                data: { ...newData, ...newParams },
                previousData: { ...preData, ...preParams }
            },
            {
                onSuccess: (data) => {

                    handleOpenSuccessConfirmForShipping(`${shippingName}運單取號`, data);

                },
                onError: (err = {}) => {

                    handleFailedMessage(`${shippingName}運單取號`, `取號失敗 ${err?.message && `(${err?.message})`}`);

                }
            }
        );

        setIsOpenConfirm(false);

    }, [formik.values, handleFailedMessage, handleOpenSuccessConfirmForShipping, record, update]);

    return (
        <>
            <Button
                size="medium"
                onClick={handleOpenConfirm}
            >
                重新取號
            </Button>
            <Confirm
                isOpen={isOpenConfirm}
                title=""
                content="您確定要重新取得單號？原本已取得的運單將會失效。"
                cancel="CANCEL"
                confirm="OK"
                onConfirm={handleRefetch}
                onClose={() => setIsOpenConfirm(false)}
            />
        </>
    );

}

RefetchShippingNumberBtn.propTypes = {
    handleOpenSuccessConfirmForShipping: PropTypes.func.isRequired,
    handleFailedMessage: PropTypes.func.isRequired,
    shippingTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    })).isRequired
};

export default RefetchShippingNumberBtn;
