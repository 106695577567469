import React from 'react';
import { useEditContext } from 'react-admin';
// import PropTypes from 'prop-types';
import {
    Grid
} from '@mui/material';

import utility from 'APP/utility';
import editConfig from 'APP/configs/editConfig';

const columnWith = 1.3;

function ProductInfo () {

    const { record } = useEditContext();

    return (
        <div className="productInfo block">
            <div className="title">商品資訊</div>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">訂購時間</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">型號</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">品牌</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">系列</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">商品圖</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">商品名稱</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">尺寸</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">數量</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableTitle">價格</Grid>
            </Grid>
            <hr />
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">{record?.orderCreatedAt ? utility.formateTime(record?.orderCreatedAt, true, undefined, 'HH:mm') : editConfig.common.emptyString}</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">{record?.product?.sku}</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">{record?.product?.brand?.name}</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">{record?.product?.series?.name}</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">
                    <img src={record?.product?.image} alt="" loading="lazy" className="productImg" />
                </Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">{record?.product?.title}</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">
                    {utility.formateSize(record?.product?.size?.sizeInfo, record?.product?.type)}
                </Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">{record?.product?.quantity}</Grid>
                <Grid item xs={columnWith} md={columnWith} className="tableItem tableItemBody">
                    {utility.priceWithCurrency(record?.product?.price, record?.product?.currency)}
                </Grid>
            </Grid>
        </div>
    );

}

export default ProductInfo;
