import api from 'APP/api';
import utility from 'APP/utility';

const custom = {
    updateMany: (resource, params) => utility.httpClient(`${api.host}/${resource}`, {
        method: 'PUT',
        body: JSON.stringify({ status: params.data.status, action: params.data.action, ids: params.ids }),
    }).then(({ headers, json }) => {

        console.log('updateMany', json, headers);
        return { data: json };
    
    }),
    // update: (resource, params) => utility.httpClient(`${api.host}/${resource}/${params.id}`, {
    //     method: 'PUT',
    //     body: JSON.stringify({ ...params.data }),
    // }).then(({ headers, json }) => {

    //     if (json.error) throw new Error(json.error?.message);
    //     return { data: json };

    // }),
    update: async (resource, params) => {

        try {

            const resData = await utility.fetchData(`${api.host}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify({ ...params.data }),
            });

            // 如果 status 2xx 卻仍有錯誤訊息
            // if (resData?.error) throw new Error(resData?.error?.message);

            return { data: resData };
        
        }
        catch (err) {

            throw new Error(err?.message);

        }

    },
    getStatisticCount: (resource, params) => utility.httpClient(`${api.host}/${resource}?filter=${JSON.stringify(params.filter)}`)
        .then(({ json }) => ({ data: json })),
    getCustomList: (resource, params) => utility.httpClient(`${api.host}/${resource}?filter=${JSON.stringify(params.filter)}`)
        .then(({ json }) => ({ data: json })),
};

export default custom;
