const apiUrl = process.env.REACT_APP__API_URL;
const wmsPrefix = 'api/wms';

export default {
    host: apiUrl,
    wmsPrefix,
    // auth
    login: `${apiUrl}/api/admin/V2/login`,
    logout: `${apiUrl}/api/admin/logout`,
    user: `${apiUrl}/api/admin/profile`,
};
