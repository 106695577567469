/* eslint-disable react/prop-types */
import React from 'react';
import { MenuItemLink } from 'react-admin';
import { Button, Box } from '@mui/material';
import {
    Dashboard, Warehouse, Verified, LocalShipping, Inventory
} from '@mui/icons-material';

import tabConfig from 'APP/configs/tabConfig';

function CustomMenuExternalLink ({ to, primaryText }) {

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Button
                href={to}
                size="large"
                sx={{
                    fontSize: '1.2rem',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    textTransform: 'initial',
                    color: 'rgba(0, 0, 0, 0.6)',
                    borderRadius: '0px',
                }}
                target="_blank"
            >
                <Dashboard
                    sx={{
                        color: 'rgba(0, 0, 0, 0.6)',
                        paddingRight: '16px'
                    }}
                />
                {primaryText}
            </Button>
        </Box>
    );

}

const iconStyle = {
    fontSize: '1.2rem',
    paddingTop: '10px',
    paddingBottom: '10px'
};

function MenuList () {

    return (
        <div>
            <CustomMenuExternalLink
                to="https://datastudio.google.com/s/s0-wkRd6Xgc"
                primaryText="Dashboard"
            />
            <MenuItemLink
                to={{
                    pathname: '/inStock',
                    search: `filter=${JSON.stringify({ status: tabConfig.pendingStorage.packageStatus, ...(!!tabConfig.pendingStorage?.cancelType && { cancelType: tabConfig.pendingStorage?.cancelType }) })}&order=${tabConfig.pendingStorage.sort.order}&sort=${tabConfig.pendingStorage.sort.field}`,
                }}
                primaryText="入庫管理"
                leftIcon={<Warehouse />}
                sx={iconStyle}
            />
            <MenuItemLink
                to={{
                    pathname: '/verification',
                    search: `filter=${JSON.stringify({ status: tabConfig.pendingVerification.packageStatus, ...(!!tabConfig.pendingVerification?.cancelType && { cancelType: tabConfig.pendingVerification?.cancelType }) })}&order=${tabConfig.pendingVerification.sort.order}&sort=${tabConfig.pendingVerification.sort.field}`,
                }}
                primaryText="商品驗證"
                leftIcon={<Verified />}
                sx={iconStyle}
            />
            <MenuItemLink
                to={{
                    pathname: '/verified',
                    search: `filter=${JSON.stringify({ status: tabConfig.TW.packageStatus, ...(!!tabConfig.TW?.destinationCountryCode && { destinationCountryCode: tabConfig.TW?.destinationCountryCode }) })}&order=${tabConfig.TW.sort.order}&sort=${tabConfig.TW.sort.field}`,
                }}
                primaryText="包裝取號"
                leftIcon={<Inventory />}
                sx={iconStyle}
            />
            <MenuItemLink
                to={{
                    pathname: '/outStock',
                    search: `filter=${JSON.stringify({ status: tabConfig.boxed.packageStatus, ...(!!tabConfig.boxed?.cancelType && { cancelType: tabConfig.boxed?.cancelType }) })}&order=${tabConfig.boxed.sort.order}&sort=${tabConfig.boxed.sort.field}`,
                }}
                primaryText="出庫管理"
                leftIcon={<LocalShipping />}
                sx={iconStyle}
            />
        </div>
    );

}

export default MenuList;
