import React from 'react';
import { useRecordContext } from 'react-admin';

function SubOrderLinkField (props) {

    const record = useRecordContext(props);
    const erpUrl = process.env.REACT_APP__ERP_URL;

    return (
        <a href={`${erpUrl}/new-buyer-order-info?id=${record.orderId}`} target="_blank" rel="noreferrer">
            {record.subOrderId}
        </a>
    );

}

export default React.memo(SubOrderLinkField);
