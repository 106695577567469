import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    useUpdateMany, Confirm, useUnselectAll, useListContext
} from 'react-admin';
import { Button } from '@mui/material';

function UpdatePackagesButton ({ selectedIds, status, text }) {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);
    const [messageTitle, setMessageTitle] = useState('');
    const unselectAll = useUnselectAll('package');
    const { refetch } = useListContext();
    const [updateMany, { isLoading }] = useUpdateMany(
        'package',
        { ids: selectedIds, data: { action: 'save_form', status } },
        {
            onSuccess: () => {

                setOpen(true);
                setMessageTitle('Update Success');
                setMessage(`更新成功`);
                unselectAll();
                refetch();

            },
            onError: (onError) => {

                setOpen(true);
                setMessageTitle('Update Failed');
                setMessage(`批次更新失敗。（請確認下列貨件單狀態：${onError.message}）`);
                unselectAll();

            }
        }
    );

    const handleClick = () => {

        updateMany();

    };

    return (
        <>
            <Button
                disabled={isLoading}
                onClick={handleClick}
            >
                {text}
            </Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={messageTitle}
                content={message}
                cancel=""
                CancelIcon={() => {}}
                onClose={() => setOpen(false)}
                onConfirm={() => setOpen(false)}
            />
        </>
    );

}

UpdatePackagesButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    status: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
};

export default React.memo(UpdatePackagesButton);
