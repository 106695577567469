import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import { Box } from '@mui/material';

function ExportCustomField ({ types, ...otherProps }) {

    const record = useRecordContext(otherProps);

    return (
        <Box sx={{ display: 'flex' }}>
            {types.find((type) => type.code === record?.customsInfo?.exportCustomsType)?.name || '-'}
        </Box>
    );

}

ExportCustomField.propTypes = {
    types: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
};

export default React.memo(ExportCustomField);
