import InStockList from './InStockList';
import VerificationList from './VerificationList';
import VerifiedList from './VerifiedList';
import OutStockList from './OutStockList';
import Edit from './Edit';
import Show from './Show';

export default {
    inStockList: InStockList,
    verificationList: VerificationList,
    verifiedList: VerifiedList,
    outStockList: OutStockList,
    edit: Edit,
    show: Show,
};
