import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';

function PromptIfDirty () {

    const formik = useFormikContext();

    const beforeunloadAction = useCallback((event) => {

        event.preventDefault();
        
        if (
            formik.dirty
            && formik.submitCount === 0
        ) {

            // 較新的瀏覽器無法控制要出現的文字
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
            // Starting with Firefox 44, Chrome 51, Opera 38, and Safari 9.1, a generic string not under the control of the webpage will be shown instead of the returned string.
            return event.returnValue = '您的修改尚未儲存，是否確定要離開此頁面，不存取變更？';
            
        }

    }, [formik.dirty, formik.submitCount]);

    useEffect(() => {

        window.addEventListener('beforeunload', beforeunloadAction, { capture: true });

        return () => {

            window.removeEventListener('beforeunload', beforeunloadAction, { capture: true });

        };

    }, [beforeunloadAction]);

    return (<div />);

}

export default PromptIfDirty;
