/* eslint-disable prefer-promise-reject-errors */
// doc: https://marmelab.com/react-admin/doc/4.0/AuthProviderWriting.html

import api from 'APP/api';
import utility from 'APP/utility';

let userDataFetching = false;

const authProvider = {
    login: async ({ username, password }) => {

        try {

            const resData = await utility.fetchData(api.login, {
                method: 'POST',
                body: JSON.stringify({
                    username,
                    password,
                    grant_type: 'password',
                    client_id: process.env.REACT_APP__AUTH_CLIENT_ID,
                    client_secret: process.env.REACT_APP__AUTH_CLIENT_SECRET,
                    scope: '*'
                }),
            });

            localStorage.setItem('logined', 'true');
            return Promise.resolve(resData);
        
        }
        catch (err) {

            return Promise.reject(err);

        }

    },
    logout: async () => {

        const logoutOutcome = () => {

            localStorage.removeItem('logined');
            localStorage.removeItem('user');

        };

        try {

            await utility.fetchData(api.logout, { method: 'POST' });
            logoutOutcome();

            return Promise.resolve();
        
        }
        catch (err) {

            return Promise.reject(err);

        }
    
    },
    checkError: ({ status }) => {

        if (status === 401 || status === 403) {

            localStorage.removeItem('logined');
            localStorage.removeItem('user');
            return Promise.reject();
        
        }

        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    
    },
    checkAuth: () => {

        const hasAuth = (localStorage.getItem('logined') === 'true');

        // 為了能成功導到 login 頁, 先暫時這樣寫
        if (!hasAuth) window.location = `${window.location.origin}/#/login`;

        return (
            hasAuth
                ? Promise.resolve()
                // : Promise.reject({ message: 'login.required' })
                : Promise.reject()
        );

    },
    getPermissions: () => Promise.resolve(),
    getIdentity: async () => {

        try {

            if (userDataFetching) return;

            let userData = JSON.parse(localStorage.getItem('user'));

            if (!userData) {

                userDataFetching = true;

                const userDataFromApi = await utility.fetchData(api.user, {});

                const userDataObj = {
                    id: userDataFromApi?.data?.id,
                    name: userDataFromApi?.data?.name,
                    email: userDataFromApi?.data?.email,
                };

                if (userDataFromApi?.success) {
                    
                    userData = userDataObj;
                    localStorage.setItem('user', JSON.stringify(userDataObj));

                }

                if (userDataFromApi?.errors) {

                    return Promise.reject(userDataFromApi?.errors?.message);

                }

                userDataFetching = false;
            
            }
            
            return Promise.resolve({ id: userData.id, fullName: userData.name });
            
        }
        catch (error) {

            return Promise.reject(error);
        
        }
    
    }
};

export default authProvider;
