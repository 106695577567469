import React, { useMemo } from 'react';
import { useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import {
    Grid
} from '@mui/material';
import { useFormikContext } from 'formik';

import utility from 'APP/utility';
import FormSelect from 'APP/components/form/FormSelect';
import FormInput from 'APP/components/form/FormInput';

import editConfig from 'APP/configs/editConfig';

function ShippingInfo ({
    shippingTypes,
    isEdit
}) {

    const { record } = useEditContext();
    const formik = useFormikContext();
    const options = shippingTypes.filter((option) => (option.id !== editConfig.shippingCode.sf || option.id !== editConfig.shippingCode.goodmaji));
    const type = useMemo(() => {

        if (!record?.shippingInfo?.toCountry) return editConfig.common.emptyString;
        return (record?.shippingInfo?.toCountry === 'TW' || record?.shippingInfo?.toCountry === 'KC') ? '國內件' : '國外件';

    }, [record]);

    const editable = record?.status === 30 && record?.shippingInfo?.shippingCode !== editConfig.shippingCode.sf && record?.shippingInfo?.shippingCode !== editConfig.shippingCode.goodmaji && record?.shippingInfo?.shippingCode !== editConfig.shippingCode.kicksCrew;

    return (
        <div className="shippingInfo block">
            <div className="title">寄送資訊</div>
            <Grid container spacing={3}>
                <Grid item xs={2} md={2} className="tableItem tableTitle">訂單運費</Grid>
                <Grid item xs={2} md={2} className="tableItem tableTitle">類型</Grid>
                <Grid item xs={2} md={2} className="tableItem tableTitle">物流商</Grid>
                <Grid item xs={4} md={4} className="tableItem tableTitle">運單編號</Grid>
            </Grid>
            <hr />
            <Grid container spacing={3}>
                <Grid item xs={2} md={2} className="tableItem tableItemBody">{ utility.priceWithCurrency(record?.shippingInfo?.shippingFee)}</Grid>
                <Grid item xs={2} md={2} className="tableItem tableItemBody">{type}</Grid>
                <Grid item xs={2} md={2} className="tableItem tableItemBody">
                    {
                        editable ? (
                            <FormSelect
                                options={options}
                                value={formik.values.shipping}
                                handleChange={formik.handleChange('shipping')}
                                error={formik.errors.shipping}
                                disabled={!isEdit || record?.status !== 30}
                                isEdit={isEdit}
                                formControlVariant="standard"
                            />
                        ) : (record?.shippingInfo?.shippingName || editConfig.common.emptyString)
                    }
                </Grid>
                {
                    (isEdit) ? (
                        <Grid item xs={4} md={4} className="tableItem tableItemBody">
                            {
                                editable ? (
                                    <FormInput
                                        value={formik.values.shippingNumber}
                                        handleChange={formik.handleChange('shippingNumber')}
                                        error={formik.errors.shippingNumber}
                                        disabled={!isEdit || record?.status !== 30}
                                        variant="standard"
                                    />
                                ) : (record?.shippingInfo?.shippingNumber || editConfig.common.emptyString)
                            }
                        </Grid>
                    ) : (<Grid item xs={4} md={4} className="tableItem">{record?.shippingInfo?.shippingNumber || editConfig.common.emptyString}</Grid>)
                }
            </Grid>
        </div>
    );

}

ShippingInfo.propTypes = {
    shippingTypes: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })),
    isEdit: PropTypes.bool.isRequired
};

ShippingInfo.defaultProps = {
    shippingTypes: []
};

export default ShippingInfo;
