import React, { useCallback, useMemo } from 'react';
import { useUpdate, useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import {
    Dialog, DialogContent, DialogActions, Button, Box
} from '@mui/material';

import editConfig from 'APP/configs/editConfig';
import FormInput from 'APP/components/form/FormInput';
import FormSelect from 'APP/components/form/FormSelect';
import PromptIfDirty from 'APP/components/package/PromptIfDirty';
import utility from 'APP/utility';
import useCustomList from 'APP/hooks/useCustomList';

const toOptions = (data) => data.map((item) => ({ name: item.name, id: item.code }));

const toDecimal = (num) => +(num.toString()
    .replace(/[^\d^.?]+/g, '').replace(/^0+(\d)/, '$1').replace(/^\./, '0.')
    .match(/^\d*(\.?\d{0,2})/g)[0]) || '';

const schema = Yup.object({
    shippingInfo: Yup.object({
        shippingCode: Yup.string().required(editConfig.errorMsg.required),
    }),
    customsInfo: Yup.object({
        productionType: Yup.string().required(editConfig.errorMsg.required),
        originCountry: Yup.string().required(editConfig.errorMsg.required),
        packageSize: Yup.string().required(editConfig.errorMsg.required),
        length: Yup.number().when('packageSize', {
            is: 'other',
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        width: Yup.number().when('packageSize', {
            is: 'other',
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        hight: Yup.number().when('packageSize', {
            is: 'other',
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        weight: Yup.number().required(editConfig.errorMsg.required)
    })
});

function InternationalShippingPopup ({
    isOpen,
    handleClose,
    formRef,
    shippingTypes,
    packageSizes,
    originCountry,
    exportTypeData,
    handleOpenSuccessConfirm,
    handleFailedMessage,
    handleOpenSuccessConfirmForShipping,
}) {
    
    const { values: ParentFormValues } = useFormikContext();
    const { record } = useEditContext();
    const [update] = useUpdate();

    const { data: productionTypeData } = useCustomList('production_type');
    const initShippingCode = useMemo(() => {

        switch (record?.recipientInfo?.country) {

            case 'HK':
                return 'sf';
            case 'MY':
            case 'SG':
            case 'PH':
            case 'VN':
                return 'goodmaji';
            default:
                break;
        
        }

    }, [record?.recipientInfo?.country]);

    const handleClosePop = useCallback((_, reason) => {

        formRef.current.resetForm();
        handleClose(_, reason);

    }, [formRef, handleClose]);

    const handleFormSubmit = useCallback((data) => {

        const { shippingInfo, customsInfo } = data;
        const {
            length, width, hight, ...otherCustomsInfo
        } = customsInfo;

        const { newParams, preParams } = utility.dealWithUpdateCommonData(ParentFormValues, record);

        const shippingName = shippingTypes.find((item) => item.id === shippingInfo.shippingCode)?.name;

        const sendData = {
            id: record?.id,
            data: {
                status: ParentFormValues.status,
                action: editConfig.actionType.formSubmit,
                ...newParams,
                ...shippingInfo,
                ...(length && { length }),
                ...(width && { width }),
                ...(hight && { hight }),
                ...otherCustomsInfo
            },
            previousData: {
                status: record?.status,
                ...preParams
            }
        };

        update(
            editConfig.resource,
            sendData,
            {
                onSuccess: (successData) => {

                    if (successData?.shippingInfo?.shippingLabelUrl) {

                        handleOpenSuccessConfirmForShipping(`${shippingName}運單取號`, successData);
                        
                    }
                    //  沒有取號的情況
                    else {

                        handleOpenSuccessConfirm();

                    }

                    handleClosePop();

                },
                onError: (err) => {

                    handleFailedMessage(`${shippingName}運單取號`, `取號失敗 ${err?.message && `(${err?.message})`}`);

                    handleClosePop();

                }
            }
        );

    }, [ParentFormValues, record, update, handleClosePop, handleOpenSuccessConfirmForShipping, handleOpenSuccessConfirm, handleFailedMessage]);

    return (
        <Dialog
            open={isOpen}
            onClose={handleClosePop}
            fullWidth
            maxWidth="sm"
        >
            <Formik
                initialValues={{
                    shippingInfo: { shippingCode: initShippingCode },
                    customsInfo: {
                        productionType: record?.customsInfo?.productionType || '',
                        packageSize: record?.customsInfo?.packageSize || '',
                        originCountry: record?.customsInfo?.originCountry || '',
                        length: record?.customsInfo?.length || '',
                        width: record?.customsInfo?.width || '',
                        hight: record?.customsInfo?.hight || '',
                        weight: record?.customsInfo?.weight || ''
                    }
                }}
                validationSchema={schema}
                onSubmit={handleFormSubmit}
                innerRef={formRef}
                validateOnChange={false}
            >
                {({
                    handleChange, handleSubmit, values, errors, setFieldValue
                }) => (
                    <>
                        <DialogContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div>{`寄送目的地: ${record?.recipientInfo?.country || ''}`}</div>
                                <div>
                                    {`報關方式: ${record?.customsInfo?.exportCustomsType
                                        ? exportTypeData.find((item) => item.code === record?.customsInfo?.exportCustomsType)?.name
                                        : ''}`}
                                </div>
                            </Box>
                            <Box sx={{ mt: 4 }}>
                                <FormSelect
                                    labelName="請選擇商品寄送方式"
                                    labelId="shippingCode"
                                    options={shippingTypes}
                                    value={values.shippingInfo.shippingCode}
                                    handleChange={handleChange('shippingInfo.shippingCode')}
                                    error={errors.shippingInfo?.shippingCode}
                                />
                            </Box>
                            <Box sx={{ mt: 4 }}>
                                <FormSelect
                                    labelName="報關品名"
                                    labelId="customsInfo.productionType"
                                    options={toOptions(productionTypeData)}
                                    value={values.customsInfo.productionType}
                                    handleChange={handleChange('customsInfo.productionType')}
                                    error={errors.customsInfo?.productionType}
                                />
                            </Box>
                            <Box sx={{ mt: 4 }}>
                                <FormSelect
                                    labelName="商品產地"
                                    labelId="customsInfo.originCountry"
                                    options={toOptions(originCountry)}
                                    value={values.customsInfo.originCountry}
                                    handleChange={handleChange('customsInfo.originCountry')}
                                    error={errors.customsInfo?.originCountry}
                                />
                            </Box>
                            <Box sx={{ mt: 4 }}>
                                <FormSelect
                                    labelName="尺寸"
                                    labelId="customsInfo.packageSize"
                                    options={toOptions(packageSizes)}
                                    value={values.customsInfo.packageSize}
                                    handleChange={handleChange('customsInfo.packageSize')}
                                    error={errors.customsInfo?.packageSize}
                                />
                            </Box>
                            {
                                values.customsInfo.packageSize === 'other' && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mt: 4,
                                        }}
                                    >
                                        <FormInput
                                            labelName="長"
                                            value={values.customsInfo.length}
                                            handleChange={(e) => setFieldValue('customsInfo.length', toDecimal(e.target.value))}
                                            error={errors.customsInfo?.length}
                                            formControlExtraStyle={{ maxWidth: '20%' }}
                                            type="number"
                                        />
                                        <Box sx={{ mx: 1 }}>*</Box>
                                        <FormInput
                                            labelName="寬"
                                            value={values.customsInfo.width}
                                            handleChange={(e) => setFieldValue('customsInfo.width', toDecimal(e.target.value))}
                                            error={errors.customsInfo?.width}
                                            formControlExtraStyle={{ maxWidth: '20%' }}
                                            type="number"
                                        />
                                        <Box sx={{ mx: 1 }}>*</Box>
                                        <FormInput
                                            labelName="高"
                                            value={values.customsInfo.hight}
                                            handleChange={(e) => setFieldValue('customsInfo.hight', toDecimal(e.target.value))}
                                            error={errors.customsInfo?.hight}
                                            formControlExtraStyle={{ maxWidth: '20%' }}
                                            type="number"
                                        />
                                        <Box sx={{ mx: 1 }}>cm</Box>
                                    </Box>
                                )
                            }
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 4
                            }}
                            >
                                <FormInput
                                    labelName="重量"
                                    value={values.customsInfo.weight}
                                    handleChange={(e) => setFieldValue('customsInfo.weight', toDecimal(e.target.value))}
                                    error={errors.customsInfo?.weight}
                                    formControlExtraStyle={{ maxWidth: '30%' }}
                                    type="number"
                                />
                                <Box sx={{ mx: 1 }}>kg</Box>
                            </Box>
                            <PromptIfDirty />
                        </DialogContent>
                        <DialogActions
                            sx={{
                                marginRight: '14px',
                                marginBottom: '10px',
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );

}

InternationalShippingPopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formRef: PropTypes.object.isRequired,
    shippingTypes: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })),
    packageSizes: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    originCountry: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    exportTypeData: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    handleOpenSuccessConfirm: PropTypes.func.isRequired,
    handleFailedMessage: PropTypes.func.isRequired,
    handleOpenSuccessConfirmForShipping: PropTypes.func.isRequired,
};

InternationalShippingPopup.defaultProps = {
    shippingTypes: [],
};

export default InternationalShippingPopup;
