import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, useGetList } from 'react-admin';

function SizeSelect ({ status }) {

    const { data: sizeData } = useGetList('size', {
        filter: { status },
    });

    return (
        <SelectInput
            source="product.size.id"
            label="尺寸"
            optionText={(record) => `US ${record.sizeInfo.find((item) => item.kind === 'us').value}`}
            choices={sizeData || []}
        />
    );

}

SizeSelect.propTypes = {
    status: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SizeSelect;
