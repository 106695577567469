import React from 'react';

import Package from 'APP/components/package/Package';

function Edit () {

    return (
        <Package />
    );

}

export default Edit;
