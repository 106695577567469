import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    FormControl, FormHelperText, TextField, Button, InputAdornment
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ClearBtn ({ clickAction }) {

    return (
        <InputAdornment position="end">
            <Button
                variant="text"
                onClick={clickAction}
                sx={{
                    'minWidth': '40px',
                    'color': '#000',
                    '&:hover': {
                        backgroundColor: 'transparent'
                    },
                    '&:focus': {
                        'backgroundColor': 'transparent',
                        '&:after': {
                            backgroundColor: 'transparent',
                        }
                    }
                }}
            >
                <CloseIcon />
            </Button>
        </InputAdornment>
    );

}

ClearBtn.propTypes = {
    clickAction: PropTypes.func
};

ClearBtn.defaultProps = {
    clickAction: null
};

function FormInput ({
    labelName,
    disabled,
    value,
    handleChange,
    error,
    formControlExtraStyle,
    hasClearBtn,
    handleClear,
    ...otherProps
}) {

    const clearBtn = {
        InputProps: {
            endAdornment: <ClearBtn clickAction={handleClear} />,
        }
    };

    return (
        <FormControl
            // variant="filled"
            sx={{ position: 'relative', ...formControlExtraStyle }}
            disabled={disabled}
            error={!!error}
            fullWidth
        >
            <TextField
                label={labelName}
                variant="filled"
                disabled={disabled}
                value={value}
                onChange={handleChange}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...otherProps}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(hasClearBtn && !disabled) && clearBtn}
            />
            <FormHelperText sx={{ minHeight: 20, position: 'absolute', top: '100%' }}>{error}</FormHelperText>
        </FormControl>
    );

}

FormInput.propTypes = {
    labelName: PropTypes.string,
    disabled: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    handleChange: PropTypes.func,
    error: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    formControlExtraStyle: PropTypes.object,
    hasClearBtn: PropTypes.bool,
    handleClear: PropTypes.func
};

FormInput.defaultProps = {
    labelName: '',
    disabled: false,
    value: '',
    handleChange: null,
    error: '',
    formControlExtraStyle: {},
    hasClearBtn: false,
    handleClear: null
};

export default memo(FormInput);
