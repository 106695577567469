/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
    Admin, CustomRoutes, Resource, ResourceContextProvider, defaultTheme
} from 'react-admin';
import { Route, Navigate } from 'react-router-dom';

import dataProvider from 'APP/dataProvider';
import authProvider from 'APP/authProvider';

import Layout from 'APP/layout';

import packages from 'APP/views/shipments';

import tabConfig from 'APP/configs/tabConfig';

const withResource = (Component, resource) => (

    <ResourceContextProvider value={resource}>
        {Component}
    </ResourceContextProvider>

);

const theme = {
    ...defaultTheme,
    sidebar: {
        width: 200
    }
};

function App () {

    return (
        <Admin
            title="AREA 02 WMS"
            dataProvider={dataProvider}
            authProvider={authProvider}
            requireAuth
            layout={Layout}
            theme={theme}
        >
            
            <CustomRoutes>
                {/* 入庫管理 list */}
                <Route
                    exact
                    path="/inStock"
                    element={withResource(<packages.inStockList />, 'package')}
                />
         
                {/* 商品驗證 list */}
                <Route
                    path="/verification"
                    element={withResource(<packages.verificationList />, 'package')}
                />
                {/* 包裝取號 */}
                <Route
                    path="/verified"
                    element={withResource(<packages.verifiedList />, 'package')}
                />
                {/* 出庫管理 list */}
                <Route
                    path="/outStock"
                    element={withResource(<packages.outStockList />, 'package')}
                />
            </CustomRoutes>

            {/* Navigate to /inStock , 因為 react admin 至少要一個 Resource */}
            <Resource
                name="package"
                edit={packages.edit}
                show={packages.show}
                list={(
                    <Navigate to={{
                        pathname: '/inStock',
                        search: `filter=${JSON.stringify({ status: tabConfig.pendingStorage.packageStatus, ...(!!tabConfig.pendingStorage?.cancelType && { cancelType: tabConfig.pendingStorage?.cancelType }) })}&order=${tabConfig.pendingStorage.sort.order}&sort=${tabConfig.pendingStorage.sort.field}`,
                    }}
                    />
                )}
            />

        </Admin>
    );

}

export default App;
