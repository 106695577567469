import React, { useMemo } from 'react';
import {
    ListBase, TextInput, DateInput, Title, Pagination
} from 'react-admin';

import PackageList from 'APP/components/package/PackageList';
import ExportLinkButton from 'APP/components/ExportLinkButton';
import CustomTopToolbar from 'APP/components/CustomTopToolbar';
import SizeSelect from 'APP/components/package/SizeSelect';
import BrandSelect from 'APP/components/package/BrandSelect';
import ShippingSelect from 'APP/components/package/ShippingSelect';
import tabConfig from 'APP/configs/tabConfig';

export default function OutStockList () {

    // Tabs
    const tabs = [
        { id: 'boxed' },
        { id: 'shipping' },
        { id: 'completed' },
        { id: 'exception' },
        { id: 'returnToSeller' },
        { id: 'dts' },
    ];

    const totalStatus = [
        ...tabConfig[tabs[0].id].packageStatus,
        ...tabConfig[tabs[1].id].packageStatus,
        ...tabConfig[tabs[2].id].packageStatus,
        ...tabConfig[tabs[3].id].packageStatus,
        ...tabConfig[tabs[4].id].packageStatus,
        ...tabConfig[tabs[5].id].packageStatus,
    ];

    const noRepeatTotalStatus = useMemo(() => [...new Set(totalStatus)], []);

    // Filters
    const filters = useMemo(() => [
        <TextInput source="subOrderId" type="number" label="訂單商品編號" alwaysOn key="search" resettable />,
        <DateInput source="startDate" label="賣家出貨日期開始" alwaysOn key="startDate" parse={(value) => new Date(value).getTime()} />,
        <DateInput source="endDate" label="賣家出貨日期結束" alwaysOn key="endDate" parse={(value) => new Date(value).getTime()} />,
        <TextInput source="product.sku" label="型號" key="product.sku" resettable />,
        <BrandSelect source="product.brand.id" key="product.brand.name" label="品牌" status={noRepeatTotalStatus} />,
        <TextInput source="product.title" label="商品名稱" key="product.title" resettable />,
        <SizeSelect source="product.size.id" key="product.size.sizeInfo.kind" label="尺寸" status={noRepeatTotalStatus} />,
        <TextInput source="packageNumber" type="number" label="貨件編號" key="packageNumber" resettable />,
        <ShippingSelect source="shippingInfo.shippingCode" label="物流商" key="shippingInfo.shippingCode" />,
        <TextInput source="shippingInfo.shippingNumber" label="運單編號" key="shippingInfo.shippingNumber" resettable />,
    ], [noRepeatTotalStatus]);

    return (
        <ListBase
            filterDefaultValues={{
                status: tabConfig[tabs[0].id].packageStatus,
                ...(tabConfig[tabs[0].id]?.cancelType && { cancelType: tabConfig[tabs[0].id].cancelType })
            }}
            perPage={25}
            sort={tabConfig[tabs[0].id].sort}
        >
            <Title defaultTitle="出貨管理" />
            <CustomTopToolbar
                filters={filters}
            >
                <ExportLinkButton to="https://datastudio.google.com/u/0/reporting/9487e2df-ebb9-483b-9b23-00b49a83b7ab/page/p_9y5aio9hzc" />
            </CustomTopToolbar>
            <PackageList tabs={tabs} countFetchApi="statistic/count/outbound" />
            <Pagination rowsPerPageOptions={[25, 50, 100]} />
        </ListBase>
    );

}
