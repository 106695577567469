import React from 'react';
import { useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import FormInput from 'APP/components/form/FormInput';
import editConfig from 'APP/configs/editConfig';
import utility from 'APP/utility';

function Insurance ({ isEdit }) {

    const { record } = useEditContext();
    const formik = useFormikContext();

    return (
        <div className="revertShipping block">
            <div className="title">保險資訊</div>
            <Grid container spacing={3}>
                <Grid item xs={2} md={2} className="tableItem tableTitle">運送保險</Grid>
                <Grid item xs={2} md={2} className="tableItem tableTitle">保險商</Grid>
                <Grid item xs={2} md={2} className="tableItem tableTitle">保險金額</Grid>
                <Grid item xs={4} md={4} className="tableItem tableTitle">保險單號</Grid>
            </Grid>
            <hr />
            <Grid container spacing={3}>
                <Grid item xs={2} md={2} className="tableItem tableItemBody">{!!record?.insurance ? '有' : editConfig.common.emptyString}</Grid>
                <Grid item xs={2} md={2} className="tableItem tableItemBody">{record?.insurance?.vendor?.name || editConfig.common.emptyString}</Grid>
                <Grid item xs={2} md={2} className="tableItem tableItemBody">{ utility.priceWithCurrency(record?.insurance?.price)}</Grid>
                <Grid item xs={4} md={4} className="tableItem tableItemBody">
                    {
                        (isEdit) ? (
                            <FormInput
                                value={formik.values.insurance.code}
                                handleChange={formik.handleChange('insurance.code')}
                                error={formik.errors.insurance?.code}
                                disabled={!isEdit}
                                variant="standard"
                            />
                        ) : (formik.values.insurance.code) || editConfig.common.emptyString
                    }
                </Grid>
            </Grid>
        </div>
    );

}

Insurance.propTypes = {
    isEdit: PropTypes.bool
};

Insurance.defaultProps = {
    isEdit: false
};

export default Insurance;
