// eslint-disable-next-line import/namespace
import { fetchUtils } from 'react-admin';
import * as tool from './tool';

const utility = {
    ...tool.default,
    httpClient: (url, options = {}) => {

        if (!options.headers) {
    
            options.headers = new Headers({ Accept: 'application/json' });
        
        }

        options.credentials = 'include';
    
        return fetchUtils.fetchJson(url, options);
    
    }
};

export default utility;
