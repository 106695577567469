import React, { useCallback } from 'react';
import { useEditContext } from 'react-admin';
import {
    Button
} from '@mui/material';

function OpenShippingLabelBtn () {

    const { record } = useEditContext();

    const handlePrintShippingLabel = useCallback(() => {

        record?.shippingInfo?.shippingLabelUrl && window.open(record?.shippingInfo?.shippingLabelUrl, '_blank');

    }, [record]);

    return (
        <Button
            size="medium"
            onClick={handlePrintShippingLabel}
        >
            列印運單標籤
        </Button>
    );

}

export default OpenShippingLabelBtn;
