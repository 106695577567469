import React from 'react';
import { SelectInput, useGetList } from 'react-admin';

function ShippingSelect () {

    const { data: shippingData } = useGetList('shipping');

    return (
        <SelectInput
            source="shippingInfo.shippingCode"
            optionValue="code"
            label="物流商"
            choices={shippingData || []}
        />
    );

}

export default ShippingSelect;
