import React from 'react';
import { useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import {
    Grid
} from '@mui/material';
import { useFormikContext } from 'formik';

import utility from 'APP/utility';
import FormSelect from 'APP/components/form/FormSelect';
import FormInput from 'APP/components/form/FormInput';

function RevertShipping ({
    isEdit,
    shippingTypes,
}) {

    const { record } = useEditContext();
    const formik = useFormikContext();

    return (
        <div className="revertShipping block">
            <div className="title">退回賣家 寄送資訊</div>
            <Grid container spacing={3}>
                <Grid item xs={2} md={2} className="tableItem tableTitle">物流商</Grid>
                <Grid item xs={4} md={4} className="tableItem tableTitle">運單編號</Grid>
                <Grid item xs={4} md={4} className="tableItem tableTitle">寄送日期 </Grid>
            </Grid>
            <hr />
            <Grid container spacing={3}>
                <Grid item xs={2} md={2} className="tableItem tableItemBody">
                    <FormSelect
                        options={shippingTypes}
                        value={formik.values.revertShipping}
                        handleChange={formik.handleChange('revertShipping')}
                        error={formik.errors.revertShipping}
                        disabled={!isEdit}
                        isEdit={isEdit}
                        formControlVariant="standard"
                    />
                </Grid>
                <Grid item xs={4} md={4} className="tableItem tableItemBody">
                    {
                        (isEdit) ? (
                            <FormInput
                                value={formik.values.revertShippingNumber}
                                handleChange={formik.handleChange('revertShippingNumber')}
                                error={formik.errors.revertShippingNumber}
                                disabled={!isEdit}
                                variant="standard"
                            />
                        ) : (formik.values.revertShippingNumber)
                    }
                </Grid>
                <Grid item xs={4} md={4} className="tableItem tableItemBody">{utility.formateTime(record?.revertShippingInfo?.shippingAt)}</Grid>
            </Grid>
        </div>
    );

}

RevertShipping.propTypes = {
    shippingTypes: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })),
    isEdit: PropTypes.bool.isRequired
};

RevertShipping.defaultProps = {
    shippingTypes: []
};

export default RevertShipping;
