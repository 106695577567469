import dayjs from 'dayjs';
import editConfig from 'APP/configs/editConfig';

const utc = require('dayjs/plugin/utc');
const dayjsTimezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

const strip = (number, fractionDigits = 2) => Math.round(+number * (10 ** fractionDigits)) / (10 ** fractionDigits);

const formateSize = (sizeInfo, productType) => {

    const originalData = (sizeInfo instanceof Array) ? sizeInfo : [];
    const usData = originalData.find((info) => info.kind === 'us');
    const targetData = usData || originalData[0];
    const needPreTextType = ['sneaker', 'apparel'];

    let newSizeString = targetData?.value || '';
    if (needPreTextType.includes(productType)) newSizeString = `${(targetData?.kind || '').toUpperCase()} ${newSizeString}`;
    return newSizeString;

};

const priceWithCommas = (price) => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const priceOfCurrency = (price, currency) => {

    if (price !== 0 && !price) return null;

    let val = price;

    switch (currency) {
        
        case 'TWD':
            val = strip(val, 0);
            break;
        default:
            val = strip(val, 0);
            break;

    }

    return val;

};

export default {
    /**
     * 
     * @param {Number} timeStemp // 單位：秒
     * @param {Boolean} withTime 
     * @returns {String}
     */
    // 香港 timezone: Hongkong
    // 台灣 timezone: Asia/Taipei
    // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    // eslint-disable-next-line default-param-last
    formateTime: (timeStemp = 1, withTime = true, timezone = 'Asia/Taipei', timeCustomFormate) => {

        let formatString = 'YYYY-MM-DD';
        let value = '-';
      
        if (withTime) formatString += ` ${timeCustomFormate || 'HH:mm:ss'}`;
        if (timeStemp) {
      
            try {
      
                const isoTime = timeStemp && (new Date(+timeStemp * 1000)).toISOString();
                value = dayjs(isoTime).tz(timezone).format(formatString);
      
            }
            catch (err) {
      
                console.log('format time error: ', err);
      
            }
      
        }
      
        return value;
      
    },
    formateSize,
    priceWithCurrency: (price, currency = 'TW') => {

        const nullView = '-';
        let priceStr = nullView;

        if (price !== undefined && price !== null) {

            // 依幣別需要四捨五入到哪一個位數
            const priceNum = priceOfCurrency(price, currency);
            // 加逗號
            priceStr = priceWithCommas(priceNum);

        }
        else {

            priceStr = nullView;

        }

        const val = (priceStr !== nullView) ? `NT$ ${priceStr}` : nullView;

        return val;

    },
    fetchData: async (url, requestObj = {}) => {

        if (!requestObj) return;

        const request = new Request(url, {
            method: 'GET',
            ...requestObj,
            headers: new Headers({
                ...requestObj.headers,
                'Content-Type': 'application/json',
            }),
            credentials: 'include'
        });

        try {

            const res = await fetch(request);
            const data = await res.json();
    
            if (res.status < 200 || res.status >= 300) {
    
                // 因為收到的錯誤訊息格式有些混亂, 先暫時這樣
                throw new Error(data?.message || data?.error?.message || data?.errors?.message || res.statusText);
    
            }
    
            return data;

        }
        catch (err) {

            throw new Error(err.message || err);

        }
    
    },
    fetchWithCache: async (fetchFn, cacheName, cacheTime) => {

        const now = (new Date()).getTime();
        const limitTime = (cacheTime || 3) * 60 * 60 * 1000;
        let Res;
        let isFetchFromAPI = false;

        const cacheData = JSON.parse(localStorage.getItem(cacheName) || null);
        const cacheDataExpireTime = localStorage.getItem(`${cacheName}Time`);

        if (cacheData && (+cacheDataExpireTime >= now)) {

            Res = cacheData;
            isFetchFromAPI = false;

        }
        else {

            try {

                const apiRes = await fetchFn();

                if (apiRes?.data) {

                    localStorage.setItem(cacheName, JSON.stringify(apiRes?.data));
                    localStorage.setItem(`${cacheName}Time`, (now + limitTime));
                    Res = apiRes?.data;
                    isFetchFromAPI = true;

                }

            }
            catch (err) {

                console.error(`get ${cacheName} err: `, err);

            }

        }

        return { data: Res, isFetchFromAPI };

    },
    // 整理很多 status 都可以調整的欄位：倉庫, 貨架, 備註
    dealWithUpdateCommonData: (newData = {}, oldData = {}) => {

        const { insurance, customsInfo } = newData;

        const newParams = {};

        const preParams = {};

        // 已入庫後, 已出庫前皆可以變更倉庫及貨架
        if (newData.status > 0 && newData.status < 50) {

            if (newData.status === 1 || (newData.storehouseId && newData.storehouseId !== oldData.storehouseId)) {

                newParams.storehouseId = newData.storehouseId;
                preParams.storehouseId = oldData?.storehouse?.id;

            }

            if (newData.status === 1 || (newData.shelvesId && newData.shelvesId !== oldData.shelvesId)) {

                newParams.shelvesId = newData.shelvesId;
                preParams.shelvesId = oldData?.shelves?.id;
                
            }

        }

        // 已出庫前/貨件異常皆可以變更 rfid
        if ((newData.status < 50 || newData.status === 90) && (newData.rfid !== undefined && newData.rfid !== null && newData.rfid !== oldData?.rfid)) {

            newParams.rfid = newData.rfid;
            preParams.rfid = oldData?.rfid;

        }

        // 將 newData.adminMemo 為 null / undefined / '' 時的值統一為 null, 方便和 api 取得的資料比對
        const newMemo = newData.adminMemo || null;

        // 備註內容有變更
        if (newMemo !== oldData?.adminMemo) {

            newParams.adminMemo = newMemo || '';

        }

        // 保險單號有變更
        if (insurance?.code !== oldData?.insurance?.code) {

            newParams.insuranceCode = insurance?.code || '';

        }

        // 正式報關單號有變更
        if (customsInfo?.productionCustomsCode !== oldData?.customsInfo?.productionCustomsCode) {

            newParams.productionCustomsCode = customsInfo?.productionCustomsCode || '';

        }

        // 商品產地有變更
        if (customsInfo?.originCountry !== oldData?.customsInfo?.originCountry) {

            newParams.originCountry = customsInfo?.originCountry || '';

        }

        return { newParams, preParams };

    },
    // 處理貨運新資料
    dealWithUpdateNewShippingData: (shipping, shippingNumber, isRevertShip = false) => {

        const newParams = {
            shippingCode: shipping,
            shippingNumber
        };

        if (shipping === editConfig.shippingCode.self) newParams.shippingNumber = '';

        if (!isRevertShip && shipping === editConfig.shippingCode.sf) {

            delete newParams.shippingNumber;

        }

        return newParams;

    }
};
