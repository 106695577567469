import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import utility from 'APP/utility';
import editConfig from 'APP/configs/editConfig';

const manageCancelReasons = (data = []) => {

    const options = [];
    const mapping = {};

    data.forEach((cancelReason) => {

        options.push({
            id: cancelReason.type,
            name: cancelReason.reason
        });
        mapping[cancelReason.type] = cancelReason.reason;
    
    });

    return { options, mapping };

};

const useCancelReason = () => {

    const dataProvider = useDataProvider();

    const [cancelReasonData, setCancelReasonData] = useState([]);
    const [cancelReasonMapping, setCancelReasonMapping] = useState({});

    //  取得異常原因列表
    useEffect(() => {

        const fetchFn = () => (dataProvider.getList(
            'cancel_reason_option',
            {
                pagination: { page: 1, perPage: 50 },
                sort: { field: 'id', order: 'ASC' },
            },
            { ...editConfig.fetchListQueryOptions }
        ));

        (async () => {

            const { data: cancelReasonRes } = await utility.fetchWithCache(fetchFn, 'cancelReason');
            const { options, mapping } = manageCancelReasons(cancelReasonRes);
            setCancelReasonData(options);
            setCancelReasonMapping(mapping);

        })();

    }, [dataProvider]);

    return {
        cancelReasons: cancelReasonData,
        cancelReasonMapping
    };

};

export default useCancelReason;
