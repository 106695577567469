import React, {
    useCallback, useEffect, useRef, useState, useMemo
} from 'react';
import {
    Confirm,
    useUpdate,
    useGetOne,
    useGetList,
    EditContextProvider,
    useRefresh,
    useNotify
} from 'react-admin';
import PropTypes from 'prop-types';
import { Grid, Button, Box } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';

import useStorehouse from 'APP/hooks/useStorehouse';
import useShippingType from 'APP/hooks/useShippingType';
import useCancelReason from 'APP/hooks/useCancelReason';

import BaseInfo from 'APP/components/package/BaseInfo';
import StatusInfo from 'APP/components/package/StatusInfo';
import StorehouseInfo from 'APP/components/package/StorehouseInfo';
import SellerInfo from 'APP/components/package/SellerInfo';
import ReceiverInfo from 'APP/components/package/ReceiverInfo';
import ProductInfo from 'APP/components/package/ProductInfo';
import ShippingInfo from 'APP/components/package/ShippingInfo';
import RevertShipping from 'APP/components/package/RevertShipping';
import DeclarationInfo from 'APP/components/package/DeclarationInfo';
import InsuranceInfo from 'APP/components/package/InsuranceInfo';
import ChooseShippingTypePopup from 'APP/components/package/ChooseShippingTypePopup';
import InternationalShippingPopup from 'APP/components/package/InternationalShippingPopup';
import RevertShippingPopup from 'APP/components/package/RevertShippingPopup';
import OpenShippingLabelBtn from 'APP/components/package/OpenShippingLabelBtn';
import RefetchShippingNumberBtn from 'APP/components/package/RefetchShippingNumberBtn';
import FormTextarea from 'APP/components/form/FormTextarea';
import PromptIfDirty from 'APP/components/package/PromptIfDirty';
import ActionLog from 'APP/components/package/ActionLog';
import RFID from 'APP/components/package/RFID';

import editConfig from 'APP/configs/editConfig';
import utility from 'APP/utility';
import useCustomList from 'APP/hooks/useCustomList';

const initialSuccessPopupValues = {
    isOpen: false,
    title: '',
    content: '',
    confirm: '',
    onConfirm: () => {}
};

function Package ({ isEdit }) {

    const formRef = useRef(null);
    const shippingTypePopupFormRef = useRef(null);
    const revertShippingTypePopupFormRef = useRef(null);
    const { data: packageSizesData } = useCustomList('package_size_options');
    const { data: originCountryData } = useCustomList('origin_country_options');
    const notify = useNotify();
    const { id } = useParams();

    // 取得 package 詳細資料
    const { data: record, isLoading } = useGetOne(
        editConfig.resource,
        { id },
        { retry: 5 }
    );

    const { data: exportTypeData } = useCustomList('customs/export_customs_type', { destinationCountryCode: record?.recipientInfo?.country });

    // 取得 action logs 資料
    const { data: logs, refetch: refetchLogs } = useGetList(
        'action_log/reference',
        {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'operatedAt', order: 'DESC' },
            filter: { packageId: id }
        }
    );

    const [selectedStorehouseId, setSelectedStorehouseId] = useState();
    // 取得倉庫寄貨架選單資料
    const {
        storehouses, shelves, storehouseMapping, shelvesMapping
    } = useStorehouse(selectedStorehouseId);
    // 取得運送方式選單資料

    const { shippingTypes } = useShippingType(record?.recipientInfo?.country);
    // 目前只退貨到台灣, 所以退貨的運送方式選單資料暫時寫死
    const { shippingTypes: revertShippingTypes } = useShippingType('TW');

    // 取得貨件異常選項
    const { cancelReasons, cancelReasonMapping } = useCancelReason();

    // default select 倉庫第一個選項
    useEffect(() => {

        const initStorehouseId = storehouses[0] ? storehouses[0].id : undefined;
        if (record && !record?.storehouse?.id) formRef.current.setFieldValue('storehouseId', initStorehouseId);
        setSelectedStorehouseId(initStorehouseId);

    }, [record, record?.storehouse?.id, storehouses]);

    const [update] = useUpdate();
    const refresh = useRefresh();

    const [isShowShippingTypeSelect, setIsShowShippingTypeSelect] = useState(false);
    const [isShowInternationalShip, setIsShowInternationalShip] = useState(false);
    const [isShowRevertShippingSelect, setIsShowRevertShippingSelect] = useState(false);
    const [successPopup, setSuccessPopup] = useState(initialSuccessPopupValues);
    const isToLocal = useMemo(() => record?.recipientInfo?.country === 'TW' || record?.recipientInfo?.country === 'KC', [record?.recipientInfo?.country]);

    // success popup
    const handleOpenSuccessConfirm = useCallback((contentText = '') => {

        notify(contentText || '更新成功', { type: 'success', autoHideDuration: 3000 });
        // 重新取得 record
        refresh();
        // 重新取得 action logs
        if (refetchLogs && typeof refetchLogs === 'function') refetchLogs();

    }, [refresh, refetchLogs, record?.packageNumber]);

    // 順豐速遞取號相關
    const handleOpenSuccessConfirmForShipping = useCallback((title, data) => {

        setSuccessPopup({
            isOpen: true,
            title,
            content: '取號成功',
            confirm: '列印運單標籤',
            onConfirm: () => {

                setSuccessPopup(initialSuccessPopupValues);
                data?.shippingInfo?.shippingLabelUrl && window.open(data?.shippingInfo?.shippingLabelUrl, '_blank');
        
            },
        });

        // 重新取得 record
        refresh();
        // 重新取得 action logs
        if (refetchLogs && typeof refetchLogs === 'function') refetchLogs();

    }, [refresh, refetchLogs]);

    // failed popup
    const handleFailedMessage = useCallback((title, content) => {

        notify(content, { type: 'error', autoHideDuration: 3000 });

    }, []);

    // 送出表單
    const handleFormSubmit = useCallback(async (values) => {

        let newData = {
            status: values.status,
            action: editConfig.actionType.formSubmit,
        };
        const preData = {
            status: record?.status,
        };

        // 改為待入庫
        if (values.status === 0) {

            preData.storehouseId = record?.storehouse?.id;
            preData.shelvesId = record?.shelves?.id;

        }

        // 改為包裝取號, 且尚無選物流時
        if (values.status === 30 && !values.shipping) {

            if (isToLocal) {

                setIsShowShippingTypeSelect(true);
            
            }
            else {

                setIsShowInternationalShip(true);
            
            }
            return;

        }

        // status 為包裝取號, 要變更物流資料時
        if (values.status === 30
            && values.shipping
            && values.shipping !== editConfig.shippingCode.sf
        ) {

            const newShippingData = utility.dealWithUpdateNewShippingData(values.shipping, values.shippingNumber);
            newData = {
                ...newData,
                ...newShippingData
            };

            preData.shippingCode = record?.shippingInfo?.shippingCode;
            preData.shippingNumber = record?.shippingInfo?.shippingNumber;

        }

        // 改為已退回賣家, 且尚無選物流時
        if (values.status === 99 && !values.revertShipping) {

            setIsShowRevertShippingSelect(true);
            return;

        }

        // status 為已退回賣家, 要變更物流資料時
        if (values.status === 99 && values.revertShipping) {

            const newShippingData = utility.dealWithUpdateNewShippingData(values.revertShipping, values.revertShippingNumber, true);
            newData = { ...newData, ...newShippingData };

            preData.shippingCode = record?.revertShippingInfo?.shippingCode;
            preData.shippingNumber = record?.revertShippingInfo?.shippingNumber;

        }

        // 改為貨件異常
        if (values.status === 90) {

            newData.reasonType = values.cancelType;
            if (newData.reasonType === 'other') newData.reason = values.cancelReason;

            preData.reasonType = record?.cancelReason?.reasonType;
            if (newData.reasonType === 'other') preData.reason = record?.cancelReason?.reason;

        }

        const { newParams, preParams } = utility.dealWithUpdateCommonData(values, record);

        update(
            editConfig.resource,
            {
                id: record?.id,
                data: {
                    ...newData,
                    ...newParams,
                },
                previousData: { ...preData, ...preParams }
            },
            {
                onSuccess: () => {

                    // 在包裝取號, 當物流商為 GOODMAJI 時，將貨件單狀態退至「驗證通過」或「貨件異常」，更新成功訊息提示如下：
                    const goodmajiNoteText = (record.status === 30 && record.shippingInfo?.shippingCode === editConfig.shippingCode.goodmaji && (values.status === 90 || values.status === 20)) ? '更新成功 (請自行至GOODMAJI後台取消運單)' : '';
                    
                    handleOpenSuccessConfirm(goodmajiNoteText);

                },
                onError: (error) => {

                    handleFailedMessage(`Updated # ${record?.packageNumber}`, `更新失敗 ${error?.message && `(${error?.message})`}`);

                }
            }
        );

    }, [handleFailedMessage, handleOpenSuccessConfirm, record, update, isToLocal]);

    const initData = useMemo(() => ({
        status: record?.status,
        cancelType: record?.cancelReason?.type || '',
        cancelReason: record?.cancelReason?.reason || '',
        storehouseId: record?.storehouse?.id || undefined,
        shelvesId: record?.shelves?.id || undefined,
        adminMemo: record?.adminMemo || undefined,
        shipping: record?.shippingInfo?.shippingCode,
        shippingNumber: record?.shippingInfo?.shippingNumber,
        revertShipping: record?.revertShippingInfo?.shippingCode,
        revertShippingNumber: record?.revertShippingInfo?.shippingNumber,
        rfid: record?.rfid,
        customsInfo: {
            originCountry: record?.customsInfo?.originCountry,
            productionCustomsCode: record?.customsInfo?.productionCustomsCode,
        },
        insurance: {
            code: record?.insurance?.code,
        }
    }), [record]);

    // update success 時將新資料更新至表單
    useEffect(() => {

        formRef.current && formRef.current.resetForm({
            values: { ...initData }
        });

    }, [initData, record]);

    const schema = Yup.object({
        status: Yup.number().required(editConfig.errorMsg.required),
        cancelType: Yup.string().when('status', {
            is: 90,
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        cancelReason: Yup.string().when('cancelType', {
            is: 'other',
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        storehouseId: Yup.number().when('status', {
            is: (statusVal) => (statusVal !== 0 && statusVal !== 90 && statusVal !== 99),
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        shelvesId: Yup.number().when(['status'], {
            is: (statusVal) => (statusVal !== 0 && statusVal !== 90 && statusVal !== 99),
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        shipping: Yup.string().when(['status'], {
            is: (statusVal) => (statusVal === 30 && record?.shippingInfo?.shippingAt),
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        shippingNumber: Yup.string().when(['status', 'shipping'], {
            is: (statusVal, shippingVal) => (statusVal === 30 && shippingVal && shippingVal !== editConfig.shippingCode.sf && shippingVal !== editConfig.shippingCode.self && shippingVal !== editConfig.shippingCode.goodmaji && shippingVal !== editConfig.shippingCode.kicksCrew),
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        revertShipping: Yup.string().when('status', {
            is: (statusVal) => (statusVal === 99 && record?.revertShippingInfo),
            then: (sch) => sch.required(editConfig.errorMsg.required)
        }),
        revertShippingNumber: Yup.string().when(['status', 'revertShipping'], {
            is: (statusVal, revertShippingVal) => (statusVal === 99 && record?.revertShippingInfo && revertShippingVal !== 'self'),
            then: (sch) => sch.required(editConfig.errorMsg.required)
        })
    });

    if (isLoading) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                // height: 'calc(100vh - 3em)'
            }}
        >
            <EditContextProvider value={{ record }}>
                <Formik
                    initialValues={{ ...initData }}
                    validationSchema={schema}
                    onSubmit={handleFormSubmit}
                    innerRef={formRef}
                    validateOnChange={false}
                >
                    {({
                        handleChange, handleSubmit, values, errors
                    }) => (
                        <div className="editForm">
                            {
                                // 已包裝取號且選擇的物流商為順豐
                                (record?.status === 30 && !!record?.shippingInfo?.shippingLabelUrl) && (
                                    <div className="topBtn">
                                        <OpenShippingLabelBtn />
                                        {record?.shippingInfo?.shippingCode === editConfig.shippingCode.sf && (
                                            <RefetchShippingNumberBtn
                                                handleOpenSuccessConfirmForShipping={handleOpenSuccessConfirmForShipping}
                                                handleFailedMessage={handleFailedMessage}
                                                shippingTypes={shippingTypes}
                                            />
                                        )}
                                    </div>
                                )
                            }
                            <main className="main">
                                <div
                                    style={{
                                        maxHeight: 'calc(100vh - 200px)',
                                        overflow: 'auto',
                                    }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={8} md={8}>
                                            <div className="title">Order</div>
                                            {/* 貨件單號, 建立日期, 商品訂單編號 */}
                                            <BaseInfo />
                                            {/* 處理狀態, 異常原因 */}
                                            <StatusInfo
                                                isEdit={isEdit}
                                                cancelReasons={cancelReasons}
                                            />
                                            {/* 入庫日期, 出庫日期, 倉庫選單, 貨架選單 */}
                                            <StorehouseInfo
                                                storehouseOptions={storehouses}
                                                shelfOptions={shelves}
                                                isEdit={isEdit}
                                            />
                                            {!!record?.area02Memo && (
                                                <Box sx={{ mt: 3 }}>
                                                    AREA 02 備註：
                                                    <span style={{ color: 'rgb(202, 87, 87)' }}>{record?.area02Memo}</span>
                                                </Box>
                                            )}
                                            {!!record?.thirdPartyMemo && (
                                                <Box sx={{ mt: 3 }}>
                                                    外部訂單出貨備註：
                                                    <span style={{ color: 'rgb(202, 87, 87)' }}>{record?.thirdPartyMemo}</span>
                                                </Box>
                                            )}
                                        </Grid>
                                        <Grid item xs={4} md={4}>
                                            <div className="title">Customer</div>
                                            {/* 賣家資訊 */}
                                            <SellerInfo />
                                            {/* 收件人資訊 */}
                                            <ReceiverInfo />
                                        </Grid>
                                    </Grid>
                                    {/* 商品資訊 */}
                                    <ProductInfo />
                                    {/* RFID */}
                                    <RFID isEdit={isEdit} />
                                    {/* 寄送資訊 */}
                                    <ShippingInfo
                                        shippingTypes={shippingTypes}
                                        isEdit={isEdit}
                                    />
                                    {
                                        (record?.status === 99) && (
                                        // 退貨資訊
                                            <RevertShipping
                                                shippingTypes={revertShippingTypes}
                                                isEdit={isEdit}
                                            />
                                        )
                                    }
                                    {
                                        !isToLocal && (
                                            <>
                                                {/* 報關資訊 */}
                                                <DeclarationInfo
                                                    isEdit={isEdit}
                                                    packageSizes={packageSizesData}
                                                    originCountry={originCountryData}
                                                    exportTypeData={exportTypeData}
                                                />
                                                {/* 保險資訊 */}
                                                <InsuranceInfo
                                                    isEdit={isEdit}
                                                />
                                            </>
                                        )
                                    }
                                    {/* 備註 */}
                                    <div className="memoInfo block">
                                        <div className="title memoTitle">備註</div>
                                        <FormTextarea
                                            value={values.adminMemo}
                                            handleChange={handleChange('adminMemo')}
                                            error={errors.adminMemo}
                                            minRows={3}
                                            disabled={!isEdit}
                                        />
                                    </div>
                                </div>
                                {(isEdit) && (
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmit}
                                        sx={{ marginTop: '16px' }}
                                    >
                                        SAVE
                                    </Button>
                                )}
                            </main>
                            {
                                (isEdit) && (
                                    <>
                                        {/* 處理有變動未 save 要離開頁面警告 */}
                                        <PromptIfDirty />
                                        {/* 選擇寄送給買家運送方式 */}
                                        <ChooseShippingTypePopup
                                            isOpen={isShowShippingTypeSelect}
                                            handleClose={(_, reason) => {

                                                // 使按 backdrop 時不會關閉 popup
                                                if (reason !== 'backdropClick') setIsShowShippingTypeSelect(false);

                                            }}
                                            formRef={shippingTypePopupFormRef}
                                            shippingTypes={shippingTypes}
                                            handleOpenSuccessConfirm={handleOpenSuccessConfirm}
                                            handleFailedMessage={handleFailedMessage}
                                            handleOpenSuccessConfirmForShipping={handleOpenSuccessConfirmForShipping}
                                        />
                                        <InternationalShippingPopup
                                            isOpen={isShowInternationalShip}
                                            handleClose={(_, reason) => {

                                                // 使按 backdrop 時不會關閉 popup
                                                if (reason !== 'backdropClick') setIsShowInternationalShip(false);

                                            }}
                                            formRef={shippingTypePopupFormRef}
                                            shippingTypes={shippingTypes}
                                            packageSizes={packageSizesData}
                                            originCountry={originCountryData}
                                            exportTypeData={exportTypeData}
                                            handleOpenSuccessConfirm={handleOpenSuccessConfirm}
                                            handleFailedMessage={handleFailedMessage}
                                            handleOpenSuccessConfirmForShipping={handleOpenSuccessConfirmForShipping}
                                        />
                                        {/* 選擇退回賣家運送方式 */}
                                        <RevertShippingPopup
                                            isOpen={isShowRevertShippingSelect}
                                            shippingTypes={revertShippingTypes}
                                            handleClose={(_, reason) => {

                                                // 使按 backdrop 時不會關閉 popup
                                                if (reason !== 'backdropClick') setIsShowRevertShippingSelect(false);

                                            }}
                                            formRef={revertShippingTypePopupFormRef}
                                            handleOpenSuccessConfirm={handleOpenSuccessConfirm}
                                            handleFailedMessage={handleFailedMessage}
                                        />
                                        {/* 成功的popup */}
                                        <Confirm
                                            isOpen={successPopup.isOpen}
                                            title={successPopup.title}
                                            content={successPopup.content}
                                            cancel="關閉"
                                            confirm={successPopup.confirm}
                                            onConfirm={successPopup.onConfirm}
                                            onClose={() => setSuccessPopup(initialSuccessPopupValues)}
                                        />
                                    </>
                                )
                            }
                        </div>
                    )}
                </Formik>
            </EditContextProvider>
            <ActionLog
                logs={logs}
                cancelReasonMapping={cancelReasonMapping}
                storehouseMapping={storehouseMapping}
                shelvesMapping={shelvesMapping}
            />
        </Box>
    );

}

Package.propTypes = {
    isEdit: PropTypes.bool,
};

Package.defaultProps = {
    isEdit: true
};

export default Package;
