import React from 'react';
import { useEditContext } from 'react-admin';

function ReceiverInfo () {

    const { record } = useEditContext();

    return (
        <div className="receiverInfo block">
            <div className="title">收件人資訊：</div>
            <div>{record?.recipientInfo?.name}</div>
            <div>{record?.recipientInfo?.phone}</div>
            <div>{`${record?.recipientInfo?.zipcode || ''} ${record?.recipientInfo?.country || ''} ${record?.recipientInfo?.city || ''} ${record?.recipientInfo?.district || ''} ${record?.recipientInfo?.address || ''}`}</div>
        </div>
    );

}

export default ReceiverInfo;
