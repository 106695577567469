import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import utility from 'APP/utility';
import editConfig from 'APP/configs/editConfig';

const manageShippingOptions = (data = []) => {

    const options = [];
    const mapping = {};

    data.forEach((shippingType) => {

        options.push({
            id: shippingType.code,
            name: shippingType.name
        });
        mapping[shippingType.code] = shippingType.name;
    
    });

    return { options, mapping };

};

const useShippingType = (countryCode) => {

    const dataProvider = useDataProvider();

    const [shippingTypeData, setShippingTypeData] = useState([]);
    const [shippingTypeMapping, setShippingTypeMapping] = useState({});

    //  取得倉庫列表
    useEffect(() => {

        if (!countryCode) return;

        (async () => {

            const fetchFn = () => (dataProvider.getList(
                'shipping',
                {
                    pagination: { page: 1, perPage: 50 },
                    sort: {},
                    ...(countryCode && {
                        filter: {
                            destinationCountryCode: countryCode,
                        }
                    })
                },
                { ...editConfig.fetchListQueryOptions }
            ));

            const { data: shippingTypeRes } = await utility.fetchWithCache(fetchFn, `shippingType${countryCode}`);
            const { options: shippingTypeOptions, mapping } = manageShippingOptions(shippingTypeRes);
            setShippingTypeData(shippingTypeOptions);
            setShippingTypeMapping(mapping);

        })();

    }, [dataProvider, countryCode]);

    return {
        shippingTypes: shippingTypeData,
        shippingTypeMapping
    };

};

export default useShippingType;
