import React from 'react';
import { Layout as DefaultLayout } from 'react-admin';

import Menu from './Menu';

export default function Layout (props) {

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DefaultLayout {...props} menu={Menu} />;

}
