import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    Select, MenuItem, FormControl, FormHelperText, InputLabel
} from '@mui/material';

function FormSelect ({
    labelName,
    labelId,
    options,
    disabled,
    value,
    handleChange,
    error,
    isEdit,
    formControlExtraStyle,
    formControlVariant
}) {

    return (
        <FormControl
            variant={formControlVariant || (isEdit ? 'filled' : 'standard')}
            sx={{ position: 'relative', ...formControlExtraStyle }}
            disabled={disabled}
            error={!!error}
            fullWidth
        >
            { labelName && (<InputLabel id={labelId}>{labelName}</InputLabel>) }
            <Select
                labelId={labelId}
                value={value}
                onChange={handleChange}
                sx={{ textAlign: 'center' }}
            >
                {
                    options.map((option) => (
                        <MenuItem
                            key={`${option.id}_${option.name}`}
                            value={option.id}
                            disabled={option.disabled}
                        >
                            {option.name}
                        </MenuItem>
                    ))
                }
            </Select>
            { isEdit && (<FormHelperText sx={{ minHeight: 20, position: 'absolute', top: '100%' }}>{error}</FormHelperText>)}
        </FormControl>
    );

}

FormSelect.propTypes = {
    labelName: PropTypes.string,
    labelId: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })),
    disabled: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    handleChange: PropTypes.func,
    error: PropTypes.string,
    isEdit: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    formControlExtraStyle: PropTypes.object,
    formControlVariant: PropTypes.string
};

FormSelect.defaultProps = {
    labelName: '',
    labelId: '',
    options: [],
    disabled: false,
    value: '',
    handleChange: null,
    error: '',
    isEdit: true,
    formControlExtraStyle: {},
    formControlVariant: null
};

export default memo(FormSelect);
