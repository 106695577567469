const statusConfig = {

    0: {
        name: '待入庫',
        id: 0,
        editStatusOptions: [0, 1, 90],
        tabColor: '#8F8F8F'
    },

    1: {
        name: '已入庫',
        id: 1,
        editStatusOptions: [0, 1, 10, 90],
        tabColor: '#353535'
    },

    10: {
        name: '商品驗證中',
        id: 10,
        editStatusOptions: [1, 10, 20, 90],
        tabColor: '#749CC8'
    },

    20: {
        name: '驗證通過',
        id: 20,
        editStatusOptions: [10, 20, 30, 90],
        tabColor: '#94BB83'
    },

    30: {
        name: '包裝取號',
        id: 30,
        editStatusOptions: [20, 30, 50, 90],
        tabColor: '#9B85BE'
    },

    50: {
        name: '已出庫',
        id: 50,
        editStatusOptions: [50, 60, 90],
        tabColor: '#D7BD64'
    },

    60: {
        name: '已配達',
        id: 60,
        editStatusOptions: [60, 90],
        tabColor: '#94BB83'
    },

    70: {
        name: '已完成',
        id: 70,
        editStatusOptions: [70],
        tabColor: '#94BB83'
    },

    90: {
        name: '貨件異常',
        id: 90,
        editStatusOptions: [0, 1, 10, 90, 99],
        tabColor: '#CA5757'
    },

    99: {
        name: '已退回賣家',
        id: 99,
        editStatusOptions: [99],
        tabColor: '#806C5E'
    },

};

export const getStatusOptions = (nowStatus) => {

    let newOptions = [];
    
    if (!statusConfig[nowStatus]) return newOptions;

    newOptions = (statusConfig[nowStatus].editStatusOptions || []).map((statusId) => ({
        id: statusId,
        name: statusConfig[statusId] && statusConfig[statusId].name,
        ...(statusId === nowStatus) && { disabled: true }
    }));

    return newOptions;

};

export default statusConfig;
