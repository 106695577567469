import React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, Avatar } from '@mui/material';

function ProductField (props) {

    const record = useRecordContext(props);

    return (
        <Box sx={{ display: 'flex' }}>
            <Avatar
                sx={{ marginRight: '8px' }}
                src={record?.product.image}
                variant="square"
            />
            {record?.product.title}
        </Box>
    );

}

export default React.memo(ProductField);
