import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, useGetList } from 'react-admin';

function BrandSelect ({ status }) {

    const { data: brandData } = useGetList('brand', {
        filter: { status },
    });

    return (
        <SelectInput
            source="product.brand.id"
            label="品牌"
            choices={brandData || []}
        />
    );

}

BrandSelect.propTypes = {
    status: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default BrandSelect;
