import React from 'react';
import { useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import {
    Grid, Box
} from '@mui/material';
import { useFormikContext } from 'formik';

import utility from 'APP/utility';

import FormSelect from 'APP/components/form/FormSelect';

function StorehouseInfo ({
    storehouseOptions,
    shelfOptions,
    isEdit
}) {

    const { record } = useEditContext();
    const formik = useFormikContext();

    return (
        <div className="storehouseInfo block">
            <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                    <div>入庫日期：</div>
                    {utility.formateTime(record?.inStockAt)}
                </Grid>
                <Grid item xs={6} md={6}>
                    <div>出庫日期：</div>
                    {utility.formateTime(record?.outOfStockAt)}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                    <Box sx={{ mt: 2 }}>
                        <FormSelect
                            labelName="倉庫"
                            labelId="storehouseLabel"
                            options={storehouseOptions}
                            value={formik.values.storehouseId}
                            handleChange={formik.handleChange('storehouseId')}
                            error={formik.errors.storehouseId}
                            disabled={!isEdit || (record?.status >= 50 && record?.status !== 90)}
                            isEdit={isEdit}
                            formControlExtraStyle={{ maxWidth: 150 }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Box sx={{ mt: 2 }}>
                        <FormSelect
                            labelName="貨架"
                            labelId="shelvesLabel"
                            options={shelfOptions}
                            value={formik.values.shelvesId}
                            handleChange={formik.handleChange('shelvesId')}
                            error={formik.errors.shelvesId}
                            disabled={!isEdit || (record?.status >= 50 && record?.status !== 90)}
                            isEdit={isEdit}
                            formControlExtraStyle={{ maxWidth: 150 }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );

}

StorehouseInfo.propTypes = {
    storehouseOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    })),
    shelfOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    })),
    isEdit: PropTypes.bool.isRequired
};

StorehouseInfo.defaultProps = {
    storehouseOptions: [],
    shelfOptions: [],
};

export default StorehouseInfo;
