import { defaultDataProvider } from 'react-admin';

import restProvider from 'APP/dataProvider/rest';
import customDataProvider from 'APP/dataProvider/custom';

import api from 'APP/api';

// 整理 Filter 參數
function formatFilter (filter = {}) {

    const {
        product, seller, shippingInfo, startDate, endDate, packageNumber, customsInfo, destinationCountryCode, ...otherFilter
    } = filter;

    const temp = {
        ...otherFilter,
        ...(!!product?.title && { title: product.title }),
        ...(!!product?.sku && { sku: product.sku }),
        ...(!!product?.brand?.id && { brandIds: [product.brand.id] }),
        ...(!!product?.size?.id && { sizeIds: [product.size.id] }),
        ...(!!seller?.id && { sellerId: seller.id }),
        ...(!!seller?.name && { sellerName: seller.name }),
        ...(!!shippingInfo?.shippingCode && { shippingCode: [shippingInfo.shippingCode] }),
        ...(!!shippingInfo?.shippingNumber && { shippingNumber: [shippingInfo.shippingNumber] }),
        ...(!!startDate && { startDate: startDate / 1000 }),
        ...(!!endDate && { endDate: endDate / 1000 }),
        ...(!!packageNumber && { packageNumber: [packageNumber] }),
        ...(!!customsInfo?.exportCustomsType && { exportCustomsType: customsInfo.exportCustomsType }),
        ...(!!destinationCountryCode && { destinationCountryCode })
    };

    return temp;

}

// 整理 Sort 參數
function formatSort (sort = {}) {

    const { field, ...otherParams } = sort;

    const paramsMapping = {
        'product.sku': 'sku',
        'seller.id': 'sellerId',
        'shippingInfo.fetchNumberAt': 'fetchNumberAt'
    };

    const temp = {
        field: field in paramsMapping ? paramsMapping[field] : field,
        ...otherParams
    };

    return temp;

}

// Setting Up The Data Provider
const extendDataProvider = {
    ...restProvider,
    ...customDataProvider
};

export default new Proxy(defaultDataProvider, {
    get: (_, name) => async (resource, params) => {

        if (typeof name === 'symbol' || name === 'then') {

            return;
        
        }

        const { filter, sort, ...otherParams } = params;
        const newFilter = formatFilter(filter);
        const newSort = formatSort(sort);
        const newParams = { filter: newFilter, sort: newSort, ...otherParams };

        return extendDataProvider[name](`${api.wmsPrefix}/${resource}`, newParams);
        
    },

});
