import React from 'react';
import { useRecordContext } from 'react-admin';
import { Box } from '@mui/material';

function SizeField (props) {

    const record = useRecordContext(props);
    const { kind, value } = record.product.size.sizeInfo.find((item) => item.kind === 'us');

    return (
        <Box sx={{ display: 'flex' }}>
            {`${kind.toUpperCase()} ${value}`}
        </Box>
    );

}

export default React.memo(SizeField);
