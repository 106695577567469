import React, { useCallback } from 'react';
import { useEditContext } from 'react-admin';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';

import FormInput from 'APP/components/form/FormInput';

// import ResultBox from 'APP/components/ResultBox';

function RFID ({
    isEdit
}) {

    const { record } = useEditContext();
    const formik = useFormikContext();

    const handleClear = useCallback(() => {

        formik.setFieldValue('rfid', '');
        
    }, [formik]);

    // const [rfidType, setRfidType] = useState('');
    // const [rfidShow, setRfidShow] = useState(false);

    // const timerRef = React.useRef();
    
    // const handleDemoChange = (e) => {
        
    //     timerRef.current && clearTimeout(timerRef.current);

    //     const { value: rfidValue } = e.target;

    //     if (rfidValue.length === 24) {

    //         setRfidType('success');
            
    //     }
    //     else {
                
    //         setRfidType('error');
        
    //     }

    //     setRfidShow(true);

    //     timerRef.current = setTimeout(() => {
                
    //         setRfidShow(false);
        
    //     }, 5000);

    //     formik.setFieldValue('rfid', rfidValue);

    //     return formik.handleChange('rfid');

    // };

    return (
        // <>
        //     <Box sx={{
        //         mt: 3,
        //         mb: 1,
        //         maxWidth: '600px',
        //         minWidth: '400px'
        //     }}
        //     >
        //         <FormInput
        //             labelName="RFID"
        //             disabled={!isEdit || (isEdit && record?.status >= 50 && record?.status !== 90)}
        //             value={formik.values.rfid}
        //             handleChange={handleDemoChange}
        //             hasClearBtn
        //             handleClear={handleClear}
        //             inputProps={{
        //                 maxLength: 50,
        //             }}
        //             sx={{ input: { color: (formik?.values?.rfid?.length === 24 ? 'black' : 'red'), fontSize: '1.5rem' } }}
        //             // eslint-disable-next-line react/jsx-props-no-spreading
        //             {...(!isEdit && { variant: 'outlined' })}
        //         />
        //     </Box>
        //     <ResultBox
        //         type={rfidType}
        //         isVisible={rfidShow}
        //     />
        // </>
        <Box sx={{
            mt: 3,
            mb: 1,
            maxWidth: '600px',
            minWidth: '400px'
        }}
        >
            <FormInput
                labelName="RFID"
                disabled={!isEdit || (isEdit && record?.status >= 50 && record?.status !== 90)}
                value={formik.values.rfid}
                handleChange={formik.handleChange('rfid')}
                hasClearBtn
                handleClear={handleClear}
                inputProps={{
                    maxLength: 50,
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(!isEdit && { variant: 'outlined' })}
            />
        </Box>

    );

}

RFID.propTypes = {
    isEdit: PropTypes.bool.isRequired
};

export default RFID;
