import React, { useMemo } from 'react';
import {
    ListBase, TextInput, DateInput, Title, Pagination
} from 'react-admin';

import PackageList from 'APP/components/package/PackageList';
import SizeSelect from 'APP/components/package/SizeSelect';
import BrandSelect from 'APP/components/package/BrandSelect';
import CustomTopToolbar from 'APP/components/CustomTopToolbar';
import tabConfig from 'APP/configs/tabConfig';

export default function List () {

    const tabs = [
        { id: 'pendingVerification' },
        { id: 'verifying' },
        { id: 'verificationFailed' }
    ];

    const totalStatus = [
        ...tabConfig[tabs[0].id].packageStatus,
        ...tabConfig[tabs[1].id].packageStatus,
        ...tabConfig[tabs[2].id].packageStatus,
    ];

    const noRepeatTotalStatus = useMemo(() => [...new Set(totalStatus)], []);

    // Filters
    const filters = useMemo(() => [
        <TextInput source="subOrderId" type="number" label="訂單商品編號" alwaysOn key="search" resettable />,
        <DateInput source="startDate" label="賣家出貨日期開始" alwaysOn key="startDate" parse={(value) => new Date(value).getTime()} />,
        <DateInput source="endDate" label="賣家出貨日期結束" alwaysOn key="endDate" parse={(value) => new Date(value).getTime()} />,
        <TextInput source="product.sku" label="型號" key="product.sku" resettable />,
        <BrandSelect source="product.brand.id" key="product.brand.name" label="品牌" status={noRepeatTotalStatus} />,
        <TextInput source="product.title" label="商品名稱" key="product.title" resettable />,
        <SizeSelect source="product.size.id" key="product.size.sizeInfo.kind" label="尺寸" status={noRepeatTotalStatus} />,
    ], []);

    return (
        <ListBase
            filterDefaultValues={{
                status: tabConfig[tabs[0].id].packageStatus,
                ...(tabConfig[tabs[0].id]?.cancelType && { cancelType: tabConfig[tabs[0].id].cancelType })
            }}
            perPage={25}
            sort={tabConfig[tabs[0].id].sort}
        >
            <Title defaultTitle="商品驗證" />
            <CustomTopToolbar filters={filters} />
            <PackageList tabs={tabs} countFetchApi="statistic/count/verification" />
            <Pagination rowsPerPageOptions={[25, 50, 100]} />
        </ListBase>
    );

}
