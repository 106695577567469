import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useListContext, useDataProvider } from 'react-admin';
import { Tab, Box, Tabs } from '@mui/material';

import tabConfig from 'APP/configs/tabConfig';

// 取得個別 Tab 的資料總數
function PackageTabs ({
    tabs, tabId, setTabId, listIsFetching,
    countFetchApi
}) {

    const context = useListContext();
    const {
        filterValues, setFilters, setSort, sort
    } = context;
    const dataProvider = useDataProvider();
    const [totals, setTotals] = useState({});

    const handleTabChange = useCallback((event, value) => {

        setTabId(value);
        // sort 欄位不同，才需重新排序
        if (tabConfig[value].sort.field !== sort.field) setSort(tabConfig[value].sort);
        // filter 欄位
        setFilters && (
            setFilters(
                {
                    ...filterValues,
                    status: tabConfig[value].packageStatus,
                    cancelType: tabConfig[value]?.cancelType ? tabConfig[value].cancelType : [],
                    destinationCountryCode: tabConfig[value]?.destinationCountryCode ? tabConfig[value].destinationCountryCode : []
                }, {}, false
            )
        );

    }, [filterValues?.packageStatus, filterValues?.cancelType, filterValues?.destinationCountryCode, setFilters]);

    // list data fetching 時，重新取得各 tab 的資料總數
    useEffect(() => {

        (async () => {

            if (listIsFetching) return;
            const { data } = await dataProvider.getStatisticCount(`${countFetchApi}`, { filter: filterValues });
            if (data) setTotals(data);

        })();

    }, [listIsFetching]);

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '#fff' }}>
            <Tabs
                indicatorColor="primary"
                value={tabId}
                onChange={handleTabChange}
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.id}
                        value={tab.id}
                        label={totals?.[[tab.id]] ? `${tabConfig[tab.id].name} (${totals[[tab.id]]})` : tabConfig[tab.id].name}
                    />
                ))}
            </Tabs>
        </Box>
    );

}

PackageTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
        })
    ).isRequired,
    tabId: PropTypes.string.isRequired,
    setTabId: PropTypes.func.isRequired,
    countFetchApi: PropTypes.string.isRequired,
    listIsFetching: PropTypes.bool.isRequired
};

export default PackageTabs;
