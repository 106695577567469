import React from 'react';
import { useEditContext } from 'react-admin';
import {
    Grid
} from '@mui/material';

import utility from 'APP/utility';

const erpUrl = process.env.REACT_APP__ERP_URL;

const shippingCodeName = {
    kicks_crew: 'KICKS CREW'
};

function BaseInfo () {
    
    const { record } = useEditContext();

    return (
        <>
            <div className="title">
                貨件單號：
                {record?.packageNumber}
                {!!record?.orderSource && (
                    <div style={{
                        color: 'white',
                        backgroundColor: 'rgb(202, 87, 87)',
                        padding: '4px 12px',
                        display: 'inline-block',
                        marginLeft: '16px'
                    }}
                    >
                        外部訂單：
                        {shippingCodeName[record?.orderSource] || record?.orderSource}
                    </div>
                )}
            </div>
            <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                    <div>建立日期：</div>
                    {utility.formateTime(record?.createdAt)}
                </Grid>
                <Grid item xs={6} md={6}>
                    <div>商品訂單編號：</div>
                    {/* link 顯示 “訂單商品編號”, link to ERP 訂單詳細頁 */}
                    <a href={`${erpUrl}/new-buyer-order-info?id=${record?.orderId}`} target="_blank" rel="noreferrer">{record?.subOrderId}</a>
                </Grid>
            </Grid>
        </>
    );

}

export default BaseInfo;
