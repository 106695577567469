import React from 'react';
import { useRecordContext } from 'react-admin';
import { Box } from '@mui/material';
import statusConfig from 'APP/configs/statusConfig';

function StatusField (props) {

    const record = useRecordContext(props);

    return (
        <Box
            sx={{
                backgroundColor: statusConfig[record.status].tabColor,
                height: '24px',
                borderRadius: '12px',
                color: '#fff',
                display: 'inline-flex',
                alignItems: 'center',
                paddingLeft: '8px',
                paddingRight: '8px',
                fontSize: '13px',
                whiteSpace: 'nowrap'
            }}
        >
            {statusConfig[record.status].name}
        </Box>
    );

}

export default React.memo(StatusField);
