import React from 'react';
import PropTypes from 'prop-types';
import {
    TopToolbar, FilterForm, FilterContext, FilterButton
} from 'react-admin';

function CustomTopToolbar ({ children, filters }) {

    const isShowFilters = !!filters && filters.length > 0;
  
    return (
        <FilterContext.Provider value={filters}>
            <TopToolbar
                sx={{
                    minHeight: { sm: 56 },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: 1,
                    paddingTop: 1
                }}
            >
                { isShowFilters && <FilterForm /> }
                <div>
                    { isShowFilters && <FilterButton /> }
                    {children}
                </div>
            </TopToolbar>
        </FilterContext.Provider>
    );

}

CustomTopToolbar.propTypes = {
    children: PropTypes.oneOfType(
        [
            PropTypes.element,
            PropTypes.array,
        ]
    ),
    filters: PropTypes.arrayOf(PropTypes.element).isRequired
};

CustomTopToolbar.defaultProps = {
    children: null
};

export default React.memo(CustomTopToolbar);
