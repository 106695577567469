import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import { Box } from '@mui/material';

import utility from 'APP/utility';

function getValueByPath (object, path) {

    const paths = path.split('.');
    let current = object;
    while (paths.length) {

        if (typeof current !== 'object' || !current) return;
        current = current[paths.shift()];
    
    }

    return current;
    
}

function CustomDateField ({ date, ...otherProps }) {

    const record = useRecordContext(otherProps);
    
    const value = useMemo(() => getValueByPath(record, date), [record, date]);

    return (
        <Box sx={{ display: 'flex' }}>
            {value && utility.formateTime(value)}
        </Box>
    );

}

CustomDateField.propTypes = {
    date: PropTypes.string.isRequired,
};

export default React.memo(CustomDateField);
