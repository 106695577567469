import React from 'react';

import Package from 'APP/components/package/Package';

function Show () {

    return (
        <Package isEdit={false} />
    );

}

export default Show;
