import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    FormControl, FormHelperText
} from '@mui/material';
import { TextareaAutosize } from '@mui/base';

function FormTextarea ({
    disabled,
    value,
    handleChange,
    error,
    formControlExtraStyle,
    ...otherProps
}) {

    return (
        <FormControl
            sx={{ ...formControlExtraStyle }}
            disabled={disabled}
            error={!!error}
            fullWidth
        >
            <TextareaAutosize
                disabled={disabled}
                minRows={3}
                value={value}
                onChange={handleChange}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...otherProps}
            />
            <FormHelperText sx={{ minHeight: 20 }}>{error}</FormHelperText>
        </FormControl>
    );

}

FormTextarea.propTypes = {
    disabled: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    handleChange: PropTypes.func,
    error: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    formControlExtraStyle: PropTypes.object
};

FormTextarea.defaultProps = {
    disabled: false,
    value: '',
    handleChange: null,
    error: '',
    formControlExtraStyle: {}
};

export default memo(FormTextarea);
