import React from 'react';
import { SelectInput, useListContext } from 'react-admin';
import useCustomList from 'APP/hooks/useCustomList';

function ExportCustomsSelect () {

    const { filterValues } = useListContext();
    const { data } = useCustomList('customs/export_customs_type', { destinationCountryCode: filterValues?.destinationCountryCode });

    return (
        <SelectInput
            source="customsInfo.exportCustomsType"
            optionValue="code"
            label="報關方式"
            choices={data}
        />
    );

}

export default ExportCustomsSelect;
