import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';

function ExportLinkButton ({ to }) {

    return (
        <Button
            href={to}
            size="small"
            target="_blank"
        >
            <FileDownloadSharpIcon sx={{ height: '18px' }} />
            EXPORT
        </Button>
    );

}

ExportLinkButton.propTypes = {
    to: PropTypes.string.isRequired,
};

export default ExportLinkButton;
