import React from 'react';
import { useRecordContext } from 'react-admin';

function SellerLinkField (props) {

    const record = useRecordContext(props);
    // const erpUrl = process.env.REACT_APP__ERP_URL;
    const consoleUrl = process.env.REACT_APP__CONSOLE_URL;

    return (
        <a href={`${consoleUrl}/#/v1/seller/info/${record.seller.applySellerId}/show`} target="_blank" rel="noreferrer">
            {record.seller.name}
        </a>
    );

}

export default React.memo(SellerLinkField);
